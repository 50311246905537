import React, { useState, useEffect, useRef } from 'react';
import { withPrefix } from 'gatsby';
import { Parallax } from 'react-scroll-parallax';
import { graphql, StaticQuery } from 'gatsby';

//Components
import PreviewCompatibleImage from '../PreviewCompatibleImage';
import Image from '../Image/Image';

//Styles
import './KeyVisual.scss';

const KeyVisual = ({ data }) => {

	const [isMobile, setIsMobile] = useState(false);

	let svg = useRef();
	let eye = useRef();
	let iris = useRef();

	useEffect(() => {
		const onMouseMove = (e) => {
			if(!isMobile){
				let cursorX = e.pageX;
				let cursorY = e.pageY;

				let distance = calculateDistance(iris.current, cursorX, cursorY);
				let cssDist = distance / 100;

				if (cssDist > 5) cssDist = 5;

				cssDist = 6 - cssDist;

				let eyeRect = iris.current.getBoundingClientRect();
				let x = offset(iris.current).left + eyeRect.width / 2;
				let y = offset(iris.current).top + eyeRect.height / 2;
				let rad = Math.atan2(cursorX - x, cursorY - y);
				let rot = (rad * (180 / Math.PI) * -1) + 180;

				iris.current.style.transformOrigin = "85.7px 60.7px";
				iris.current.style.transform = "rotate(" + rot + "deg) translateY(" + cssDist + "px)";
			}
			
		};


		const mobileDetection = () => {
			if (window.innerWidth <= 769) {
				setIsMobile(true);
				iris.current.style.transform = "translateY(7px)";
			}
			else {
				setIsMobile(false);
			}
		}
		mobileDetection();

		window.addEventListener('resize', mobileDetection);
		window.addEventListener('mousemove', onMouseMove);

		return () => {
			window.removeEventListener('mousemove', onMouseMove);
			window.removeEventListener('resize', mobileDetection);
		};
	}, [isMobile]);


	const calculateDistance = (elem, mouseX, mouseY) => {
		let rect = elem.getBoundingClientRect(), bodyElt = document.body;
		let top = rect.top + bodyElt.scrollTop;
		let left = rect.left + bodyElt.scrollLeft;
		let width = rect.width;
		let height = rect.height;

		return Math.floor(Math.sqrt(Math.pow(mouseX - (left + (width / 2)), 2) + Math.pow(mouseY - (top + (height / 2)), 2)));
	};

	const offset = (elt) => {
		var rect = elt.getBoundingClientRect(), bodyElt = document.body;
		return {
			top: rect.top + bodyElt.scrollTop,
			left: rect.left + bodyElt.scrollLeft
		}
	};

	return (
		<section className="keyvisual hero is-primary">
			<div className="hero-body">

				<div className="background">
					<Parallax className="parallax-item wario" y={[15, -15]}>
						<PreviewCompatibleImage imageInfo={{ image: data.wario, alt: 'Wario' }} />
					</Parallax>

					<Parallax className="parallax-item kermitt" y={[-10, 10]} x={[-10, 10]}>
						<PreviewCompatibleImage imageInfo={{ image: data.kermitt, alt: 'Kermitt' }} />
					</Parallax>

					<Parallax className="parallax-item asterix" y={[15, -15]}>
						<PreviewCompatibleImage imageInfo={{ image: data.asterix, alt: 'Asterix' }} />
					</Parallax>

					<Parallax className="parallax-item yellow-pen" y={[-20, 20]}>
						<PreviewCompatibleImage imageInfo={{ image: data.yellowpen, alt: 'Yellow Pen' }} />
					</Parallax>

					<div className="logo">
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 391 92.18" ref={svg}>
							<g id="f80059c5-5f9e-448f-9e44-399ee16e58ca" data-name="Layer 2">
								<g id="fe7b8b30-caf2-4c10-b48e-50f74db4c2fe" data-name="Layer 1">
									<path d="M23.8,42.38h-10a.79.79,0,0,0-.8.8v18a.79.79,0,0,0,.8.8H24c6.6,0,11-3.9,11-9.6v-.2C35,46.08,30.8,42.38,23.8,42.38Zm9.7,9.9c0,4.9-3.8,8.1-9.5,8.1H14.5V43.88h9.3c6.3,0,9.8,2.9,9.8,8.2v.2Z" />
									<path d="M24.9,30.78H.8a.79.79,0,0,0-.8.8.6.6,0,0,0,.1.4v58.4a.79.79,0,0,0,.8.8h13a.79.79,0,0,0,.8-.8V73.58h9.1c14.9,0,24.5-8.4,24.5-21.5v-.2C48.3,39.08,39.1,30.78,24.9,30.78Zm21.9,21.3c0,12.1-9,20-23,20H14a.79.79,0,0,0-.8.8v16.8H1.6V32.28H24.9c13.3,0,21.9,7.7,21.9,19.6Z" />
									<path d="M148,30.78H124a.79.79,0,0,0-.8.8v58.8a.79.79,0,0,0,.8.8h13a.79.79,0,0,0,.8-.8V73.58h9c14.9,0,24.6-8.4,24.6-21.5v-.2C171.4,39.08,162.2,30.78,148,30.78Zm21.9,21.3c0,12.1-9,20-23.1,20H137a.79.79,0,0,0-.8.8v16.8H124.7V32.28H148c13.3,0,21.9,7.7,21.9,19.6v.2Z" />
									<path d="M147,42.38H137a.79.79,0,0,0-.8.8v18a.79.79,0,0,0,.8.8h10.2c6.6,0,11-3.9,11-9.6v-.2C158.3,46.08,154,42.38,147,42.38Zm9.8,9.9c0,4.9-3.8,8.1-9.5,8.1h-9.5V43.88h9.3c6.3,0,9.8,2.9,9.8,8.2v.2Z" />
									<path d="M223.5,78.18H192.2v-11h27a.79.79,0,0,0,.8-.8V55a.79.79,0,0,0-.8-.8h-27V43.78H223a.79.79,0,0,0,.8-.8V31.48a.79.79,0,0,0-.8-.8H178.6a.79.79,0,0,0-.8.8v58.9a.79.79,0,0,0,.8.8h44.9a.79.79,0,0,0,.8-.8V78.88A.77.77,0,0,0,223.5,78.18Zm-.7,11.5H179.4V32.28h42.9v10H191.5a.79.79,0,0,0-.8.8v12a.79.79,0,0,0,.8.8h27v10h-27a.79.79,0,0,0-.8.8v12.4a.79.79,0,0,0,.8.8h31.3Z" />
									<path d="M286.6,30.78H271.9a.55.55,0,0,0-.6.4l-13.7,22.5-13.5-22.5a.82.82,0,0,0-.6-.4H228.4a.75.75,0,0,0-.7.4,1.1,1.1,0,0,0,0,.8l22.6,35.4v23a.79.79,0,0,0,.8.8h13a.79.79,0,0,0,.8-.8V67.08l22.5-35.2a.76.76,0,0,0,0-.8A1.33,1.33,0,0,0,286.6,30.78Zm-23.2,35.7a.6.6,0,0,0-.1.4v22.8H251.8V67.18a.6.6,0,0,0-.1-.4l-21.9-34.5h13.3L257,55.48a.82.82,0,0,0,.6.4h0a.55.55,0,0,0,.6-.4l14.1-23.2h12.9Z" />
									<path d="M336.3,78.18H305v-11h27a.79.79,0,0,0,.8-.8V55a.79.79,0,0,0-.8-.8H305V43.78h30.9a.79.79,0,0,0,.8-.8V31.48a.79.79,0,0,0-.8-.8H291.5a.79.79,0,0,0-.8.8v58.9a.79.79,0,0,0,.8.8h44.8a.79.79,0,0,0,.8-.8V78.88A.85.85,0,0,0,336.3,78.18Zm-.8,11.5H292.2V32.28h42.9v10H304.2a.79.79,0,0,0-.8.8v12a.79.79,0,0,0,.8.8h27v10h-27a.79.79,0,0,0-.8.8v12.4a.79.79,0,0,0,.8.8h31.3Z" />
									<path d="M371.7,54.38c-10.2-2.6-12.2-3.7-12.2-7v-.2c0-2.6,2.7-4.3,6.9-4.3,4.4,0,9.4,1.9,14.9,5.7a.66.66,0,0,0,.6.1.55.55,0,0,0,.5-.3l6.7-9.8a.68.68,0,0,0-.2-1,35,35,0,0,0-22.4-7.7c-12.6,0-21.3,7.6-21.3,18.6,0,12.7,8.7,15.9,20.1,18.7,9.8,2.5,11.3,4,11.3,6.8v.2c0,3-2.9,4.9-7.8,4.9-5.9,0-11.3-2.2-17.1-7a.79.79,0,0,0-1.1.1l-7.7,9.2a.67.67,0,0,0,.1,1,38.63,38.63,0,0,0,25.6,9.7c13.6,0,22.4-7.5,22.4-19.1v-.2C391,61.58,383.3,57.38,371.7,54.38ZM389.5,73c0,10.8-8,17.6-20.9,17.6a37.06,37.06,0,0,1-24.1-8.8l6.8-8.1c5.8,4.7,11.4,6.9,17.5,6.9,5.7,0,9.3-2.4,9.3-6.4V74c0-4-2.7-5.8-12.5-8.2-12.8-3.2-18.9-6.6-18.9-17.4,0-10,8.2-17,19.8-17a33.46,33.46,0,0,1,20.9,6.9l-5.9,8.6c-5.5-3.7-10.5-5.5-15.1-5.5-5.8,0-8.4,2.9-8.4,5.8v.2c0,4.6,3.4,5.9,13.3,8.4,13.2,3.4,18.1,8,18.1,17V73Z" />
									<path d="M85.8,29.68c-18,0-32.1,13.7-32.1,31.3v.2c0,17.7,13.7,31,32,31,18,0,32.1-13.7,32.1-31.3v-.2C117.8,43,104,29.68,85.8,29.68Zm30.5,31.2c0,16.7-13.5,29.8-30.6,29.8-17.4,0-30.5-12.7-30.5-29.5V61c0-16.7,13.5-29.8,30.6-29.8,17.4,0,30.5,12.7,30.5,29.5Z" />
									<path className="eye" ref={eye} d="M85.6,46.18c-13.3,0-22.4,13.5-22.8,14a.6.6,0,0,0-.1.4v.2a.76.76,0,0,0,.2.5l.2.2c3.9,5.2,12.1,14,22.7,14,13.3,0,22.4-13.5,22.8-14a.6.6,0,0,0,.1-.4v-.2a.6.6,0,0,0-.1-.4C108.2,60,98.9,46.18,85.6,46.18Zm.2,28c-10,0-17.8-8.4-21.5-13.4,1.5-2.1,9.9-13,21.3-13s20.1,11.3,21.6,13.3C105.7,63.08,97.2,74.18,85.8,74.18Z" />
									{/* <path className="iris" d="M85.7,52.88a7.8,7.8,0,1,0,7.8,7.8A7.81,7.81,0,0,0,85.7,52.88Zm0,14a6.34,6.34,0,0,1-6.3-6.3,6.3,6.3,0,0,1,12.6,0A6.34,6.34,0,0,1,85.7,66.88Z" /> */}
									<path d="M117.3,10.68s-.3,2.6,3.5,2.2c0,0,4.7-.8,5.2,3.3a4.73,4.73,0,0,1-2.4,4.6s-3.6,2.2-10.6.3c0,0-6.7-1.7-6.5-10.3,0,0-.4-8.3,7.9-10.2,0,0,5.3-1.4,9.5,1.1a4.22,4.22,0,0,1,1.4,5.5s-.9,2.4-4.7,1.7C120.7,8.88,117.4,8.18,117.3,10.68Z" />
									<path d="M149.5,10.68c0,11.3-9.6,11.3-9.6,11.3-10.8,0-10.8-10.1-10.8-10.1,0-12.4,10.8-11.3,10.8-11.3C149.5.48,149.5,10.68,149.5,10.68Zm-11.2.7a1.2,1.2,0,0,0,2.4,0,1.23,1.23,0,0,0-1.2-1.3C138.8,10.18,138.2,10.78,138.3,11.38Z" />
									<path d="M170.8,15.48c.2,1.1,0,6.3-8.1,6.5-7.4.2-8.9-5.9-8.9-5.9-1.4-4.1-.3-10.8-.3-10.8.9-5.1,4.7-4.9,4.7-4.9,6.8-.2,4.5,12.7,4.5,12.7C169.7,9.38,170.8,15.48,170.8,15.48Z" />
									<path d="M194.5,10.68c0,11.3-9.6,11.3-9.6,11.3-10.8,0-10.8-10.1-10.8-10.1,0-12.4,10.8-11.3,10.8-11.3C194.5.48,194.5,10.68,194.5,10.68Zm-11.2.7a1.2,1.2,0,0,0,2.4,0,1.23,1.23,0,0,0-1.2-1.3C183.8,10.18,183.2,10.78,183.3,11.38Z" />
									<path d="M214.1,12.88c5.8,4.9,1.4,8.2,1.4,8.2-4.6,3.7-8.1-4.8-8.1-4.8.8,5.2-3.3,5.5-3.3,5.5-4,.5-5.1-3.6-5.1-3.6a21.09,21.09,0,0,1-.2-12c1.7-6.6,8.6-5.9,8.6-5.9,7,0,8.1,5.7,8.1,5.7A8.06,8.06,0,0,1,214.1,12.88Zm-7.8-3.5a1.2,1.2,0,0,0,2.4,0,1.23,1.23,0,0,0-1.2-1.3C206.8,8.18,206.2,8.78,206.3,9.38Z" />
									<path d="M220.2,7.18s.2-6.7,5.4-6.7c0,0,4.2,0,4.9,5.8a43.29,43.29,0,0,1,0,10.4s-.5,5.2-4.9,5.2c-5-.1-5.3-6.4-5.3-6.4A58.3,58.3,0,0,1,220.2,7.18Z" />
									<path d="M240.5.18c6.7-.3,6.8,7.9,6.8,7.9-.1-8.2,4.8-7.7,4.8-7.7a4.06,4.06,0,0,1,4.3,3.4,36.05,36.05,0,0,1-.2,13.3c-1.1,5.1-4.8,4.5-4.8,4.5-6.5.1-7.4-8.9-7.4-8.9.5,9.8-4.8,8.9-4.8,8.9a4.14,4.14,0,0,1-4.1-3.3,34.71,34.71,0,0,1,0-13.3A5.74,5.74,0,0,1,240.5.18Z" />
									<path d="M271.7,11.08c.5-1.4,4.5-1.3,6.6-1a2.67,2.67,0,0,1,1.9,1c1.5,1.9.8,8.6.8,8.6a3.17,3.17,0,0,1-3.1,3.2c-1.7,0-3.6-.7-3.5-3-.9,1.1-3.2,2.4-7,1.7,0,0-7.6-1-7.6-10.6,0,0-.4-8,7.6-10.6,0,0,6.2-1.8,10.2,2.4a4.28,4.28,0,0,1,.6,4.6s-1,2.5-5.2.9c0,0-3.9-1.3-3.9,2.5,0,0,0,3.9,3.9,2.5C271,12.48,271.7,11.08,271.7,11.08Z" />
								</g>
								<circle cx="85.7" cy="54" r="7" stroke="black" strokeWidth="1.5" fill="rgba(0, 0, 0, 0)" ref={iris} />
							</g>
						</svg>
					</div>

					<Parallax className="parallax-item spongebob" y={[-25, 25]}>
						<PreviewCompatibleImage imageInfo={{ image: data.spongebob, alt: 'Sponge Bob' }} />
					</Parallax>

					<Parallax className="parallax-item bart" y={[-25, 25]}>
						<Image
							src={`${withPrefix('/')}img/keyvisual/bart.png`}
							width="418" height="228" alt="Bart"
						/>
					</Parallax>
				</div>
			</div>
		</section>
	);
};

// export default KeyVisual;
export default () => (
	<StaticQuery
		query={graphql`
			query KeyVisualQuery {
				wario: file(relativePath: { eq: "keyvisual/wario.png" }) {
					childImageSharp {
						fluid(maxWidth: 636, quality: 100) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
				spongebob: file(relativePath: { eq: "keyvisual/spongebob.png" }) {
					childImageSharp {
						fluid(maxWidth: 576, quality: 100) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
				kermitt: file(relativePath: { eq: "keyvisual/kermitt.png" }) {
					childImageSharp {
						fluid(maxWidth: 432, quality: 100) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
				asterix: file(relativePath: { eq: "keyvisual/asterix.png" }) {
					childImageSharp {
						fluid(maxWidth: 444, quality: 100) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
				yellowpen: file(relativePath: { eq: "keyvisual/yellow-pen.png" }) {
					childImageSharp {
						fluid(maxWidth: 308, quality: 100) {
							...GatsbyImageSharpFluid_withWebp_noBase64
						}
					}
				}
			}
		`}
		render={(data, count) =>
			<KeyVisual data={data} />
		}
	/>
);
