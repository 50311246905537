import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql, withPrefix } from 'gatsby';
import remark from 'remark';
import remarkHTML from 'remark-html';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import { Parallax } from 'react-scroll-parallax';

//Components
import Layout from '../components/Layout';
import KeyVisual from '../components/KeyVisual/KeyVisual';
import ColoringList from '../components/ColoringList';
import PreviewCompatibleImage from '../components/PreviewCompatibleImage';
import Image from '../components/Image/Image';

//Contexts
import { NavigationContext } from '../components/NavigationContext';

const toHTML = value => remark()
	.use(remarkHTML)
	.processSync(value)
	.toString()

export const IndexPageTemplate = ({
	title,
	mainpitch,
	about,
	credits,
	images
}) => {

	const anchorContext = useContext(NavigationContext);
	gsap.registerPlugin(ScrollToPlugin);

	useEffect(() => {
		if (anchorContext.anchor != null){
			gsap.to(window, { duration: 1, scrollTo: anchorContext.anchor, ease: 'power2.inOut' });

			anchorContext.setAnchor(null);
		}
	}, [anchorContext]);

	return (
		<>
			<KeyVisual />

			<div className="columns is-centered is-mobile">
				<div className="column is-half-desktop is-10-mobile">
					<div className="has-text-centered" dangerouslySetInnerHTML={{ __html: toHTML(mainpitch.description) }}></div>
				</div>
			</div>
			
			<ColoringList itemsCount={9} random={true} more={true} />

			<div className="columns is-centered is-marginless section-about" id="about">
				<div className="background">
					<Parallax className="parallax-item printer" y={[40, 0]}>
						<Image
							src={`${withPrefix('/')}img/keyvisual/printer.png`}
							width="387" height="441" alt="Printer"
						/>
					</Parallax>

					<Parallax className="parallax-item beige-pen" y={[20, -20]}>
						<PreviewCompatibleImage imageInfo={{ image: images.beigepen, alt: 'Beige Pen' }} />
					</Parallax>
				</div>

				<div className="column is-half is-paddingless">
					<h2 className="title is-1 has-text-centered">{about.title}</h2>
					<div className="has-text-centered" dangerouslySetInnerHTML={{ __html: toHTML(about.description) }}></div>
				</div>
			</div>

			<div className="columns is-centered section-credits" id="credits">
				<div className="background">
					<Parallax className="parallax-item baloo" y={[15, -15]}>
						<PreviewCompatibleImage imageInfo={{ image: images.baloo, alt: 'Baloo' }} />
					</Parallax>
				</div>

				<div className="column is-half">
					<h2 className="title is-1 has-text-centered">{credits.title}</h2>
					<div className="has-text-centered" dangerouslySetInnerHTML={{ __html: toHTML(credits.description) }}></div>
				</div>
			</div>
		</>
	)
};

IndexPageTemplate.propTypes = {
	title: PropTypes.string,
	mainpitch: PropTypes.object,
	about: PropTypes.object,
	credits: PropTypes.object,
};

const IndexPage = ({ data }) => {
	const { frontmatter } = data.markdownRemark;

	return (
		<Layout>
			<IndexPageTemplate
				title={frontmatter.title}
				mainpitch={frontmatter.mainpitch}
				about={frontmatter.about}
				credits={frontmatter.credits}
				images={{
					baloo: data.baloo,
					beigepen: data.beigepen
				}}
			/>
		</Layout>
	)
};

IndexPage.propTypes = {
	data: PropTypes.shape({
		markdownRemark: PropTypes.shape({
			frontmatter: PropTypes.object,
		}),
	}),
};

export default IndexPage;

export const pageQuery = graphql`
	query IndexPageTemplate {
		markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
			frontmatter {
				title
				mainpitch {
					description
				}
				about {
					title
					description
				}
				credits {
					title
					description
				}
			}
		}
		baloo: file(relativePath: { eq: "keyvisual/baloo.png" }) {
			childImageSharp {
				fluid(maxWidth: 504, quality: 100) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
		beigepen: file(relativePath: { eq: "keyvisual/beige-pen.png" }) {
			childImageSharp {
				fluid(maxWidth: 202, quality: 100) {
					...GatsbyImageSharpFluid_withWebp_noBase64
				}
			}
		}
	}
`
