import React, { useState, useRef, useEffect } from 'react';

export default ({ src, width, height, alt }) => {

	let img = useRef();
	const [isLoaded, setIsLoaded] = useState(false);

	useEffect(() => {
		const load = () => {
			let img = new Image();
			img.onload = () => {
				setIsLoaded(true);
			};
			img.src = src;
		}

		load();
	}, [src]);

	return (
		<div className={`image-loader ${isLoaded ? 'loaded' : ''}`}>
			{isLoaded ? (
				<img
					src={src}
					width={width} height={height} alt={alt}
					ref={img} loading="lazy"
				/>
			) : null}
		</div>
	);
};